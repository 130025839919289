<template>
  <v-content>
    <v-container fluid>
      <v-row align="center"
             justify="center">
        <v-col cols="12"
               sm="10"
               md="6">
          <v-card class="pa-8">
            <v-card-title>{{ schema.title }}</v-card-title>
            <DynamicForm :fields="fields" :formName="'OSBB'" :formData="formData" :submitCallback="submitCallback"/>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-content>
</template>

<script>
  import DynamicForm from '@/components/generator/DynamicForm.vue'
  import { get } from 'lodash'
    export default {
        name: "GenerateForm",
        components: {
            DynamicForm
        },
        // props: {
        //   formData: {
        //     type: Object,
        //     required: false,
        //     default: () => {}
        //   }
        // },
        data: () => {
            return {
              schema: {
                title: 'Оплата по {{ formData.organization.name }}. Послуга для оплати {{ formData.organization.service }}',
              },
              fields: [
                    {
                        name: 'IBAN',
                        type: 'input',
                        row: 1,
                        cols: 8,
                        value: 'formData.IBAN',
                        schema: {
                            fieldType: 'text',
                            label: 'Розрахунковий рахунок',
                            disabled: true,
                            placeholder: 'Введіть р/р в форматі IBAN',
                            validators: ['required']
                        },
                        styleSchema: {
                          outlined: true,
                        }
                    },
                    {
                      name: 'ZKPO',
                      type: 'input',
                      row: 1,
                      cols: 4,
                      value: 'formData.ZKPO',
                      schema: {
                        fieldType: 'text',
                        label: 'ЄДРПОУ',
                        placeholder: 'Введіть ЄДРПОУ',
                        disabled: true,
                        validators: ['required']
                      },
                      styleSchema: {
                        outlined: true,
                      }
                    },
                    {
                      name: 'PIB',
                      type: 'input',
                      row: 2,
                      cols: 12,
                      value: '',
                      schema: {
                        fieldType: 'text',
                        label: 'Платник',
                        placeholder: 'П.І.Б платника',
                        validators: ['required']
                      },
                      styleSchema: {
                        outlined: true,
                      }
                    },
                    {
                      name: 'ADDRESS',
                      type: 'input',
                      row: 3,
                      cols: 12,
                      value: '',
                      schema: {
                        fieldType: 'text',
                        label: 'Адреса',
                        placeholder: 'Введіть адресу',
                        validators: []
                      },
                      styleSchema: {
                        outlined: true,
                      }
                    },
                    {
                      name: 'COMMENTS',
                      type: 'input',
                      row: 3,
                      cols: 12,
                      value: '',
                      schema: {
                        fieldType: 'text',
                        label: 'Адреса',
                        placeholder: 'Введіть адресу',
                        validators: []
                      },
                      styleSchema: {
                        outlined: true,
                      }
                    },
                    {
                      name: 'SUM',
                      type: 'input',
                      row: 4,
                      cols: 6,
                      value: '',
                      schema: {
                        fieldType: 'number',
                        label: 'Сума',
                        placeholder: 'Сума',
                        validators: []
                      },
                      styleSchema: {
                        outlined: true,
                      }
                    },
                    // {
                    //     name: 'Name',
                    //     type: 'input',
                    //     row: 1,
                    //     cols: 6,
                    //     value: '',
                    //     schema: {
                    //         fieldType: 'text',
                    //         label: 'Ім’я',
                    //         placeholder: 'Введіть ім’я',
                    //         validators: ['required']
                    //     },
                    //     styleSchema: {
                    //         solo: true,
                    //         class: 'test',
                    //         prependIcon: 'mdi-account'
                    //     }
                    // },
                    // {
                    //     name: 'LastName',
                    //     type: 'input',
                    //     row: 2,
                    //     cols: 12,
                    //     value: '',
                    //     schema: {
                    //         fieldType: 'text',
                    //         label: 'По батькові',
                    //         placeholder: 'Введіть по батькові',
                    //         validators: ['required']
                    //     },
                    //     styleSchema: {
                    //         solo: true,
                    //         class: 'test',
                    //         prependIcon: 'mdi-account'
                    //     }
                    // },
                    // {
                    //     name: 'Person_Id_Internal',
                    //     type: 'input',
                    //     row: 3,
                    //     cols: 6,
                    //     value: '',
                    //     schema: {
                    //         fieldType: 'number',
                    //         label: 'Особовий рахунок',
                    //         placeholder: 'Введіть особовий',
                    //         validators: ['required']
                    //     },
                    //     styleSchema: {
                    //         solo: true,
                    //         color: 'success',
                    //         class: 'test',
                    //         prependIcon: 'mdi-cart'
                    //     }
                    // },
                    // {
                    //     name: 'age18',
                    //     row: 3,
                    //     cols: 6,
                    //     type: 'checkbox',
                    //     value: true,
                    //     schema: {
                    //         fieldType: 'checkbox',
                    //         label: 'Мені вже 18',
                    //         validators: ['required'],
                    //     },
                    //     styleSchema: {
                    //         color: 'primary',
                    //         class: 'ml-8'
                    //     }
                    // },
                    // {
                    //     name: 'Services',
                    //     type: 'select',
                    //     value: 1,
                    //     schema: {
                    //         label: 'Послуги',
                    //         placeholder: 'Оберіть послугу',
                    //         validators: ['required'],
                    //         callback: 'services'
                    //     },
                    //     styleSchema: {
                    //         solo: true,
                    //         class: 'test',
                    //         prependIcon: 'mdi-account'
                    //     }
                    // },
                    // {
                    //     name: 'Start_date',
                    //     type: 'date',
                    //     value: '2019-10-02',
                    //     schema: {
                    //         label: 'Дата початку',
                    //         placeholder: 'Введіть дату',
                    //         validators: ['required']
                    //     },
                    //     styleSchema: {
                    //         solo: true,
                    //         color: 'success',
                    //         class: 'test',
                    //         prependIcon: 'mdi-calendar'
                    //     }
                    // },
                ],
              formData: {
                IBAN: '123456789',
                ZKPO: '11223344',
                organization: {
                  name: 'ОСББ В. Великого 8',
                  service: 'Утримання будинку'
                }
              }
            }
        },
        methods: {
            submitCallback(form) {
              this.$snackbar(form, 'error')
            },

            beforeData() {
              for (let key in this.schema) {
                let itemValue = this.schema[key];

                let re = new RegExp('\\{\\{[ ]{0,}formData\\.?[^\\}]*\\}\\}', 'g');
                let rRe = itemValue.match(re);

                let formData = this.formData;

                rRe.forEach(item =>  {
                  let forReplace = '' + item;
                  let replacePath = forReplace.replace('{{', '').replace('}}', '').replace('formData.', '').trim();

                  itemValue = itemValue.replace(forReplace, get(formData, replacePath, ''))
                });

                this.schema[key] = itemValue;
              }
            }
        },
      mounted() {
          this.beforeData();
      }
    }
</script>

<style scoped>

</style>
